import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { PageTemplate } from "../../templates";
import locales from "../../locales";
import usePaymentProtectionInsuranceProgram from "./hooks/usePaymentProtectionInsurance";

const PaymentProtectionInsurance = () => {
  const intl = useIntl();

  const {
    locale,
    environment,
    shopperPublicToken
  } = usePaymentProtectionInsuranceProgram();

  useEffect(() => {
    if (window.weecoverWidget) {
      const {
        render: renderWeecoverWidget
      } = window.weecoverWidget.default.widget("weecover_widget");
      renderWeecoverWidget(locale, environment, shopperPublicToken);
    }
  }, [locale, environment, shopperPublicToken]);

  return (
    <PageTemplate
      title={intl.formatMessage(
        locales.payment_protection_insurance_page_title
      )}
    >
      <div id="weecover_widget" />
    </PageTemplate>
  );
};

export default PaymentProtectionInsurance;
