import { useEffect } from "react";
import { useSetState } from "react-use";
import usePaymentProtectionInsuranceDataQuery from "./queries/usePaymentProtectionInsuranceData";

export const initialState = {
  fetching: true,
  fetchingError: false,
  fetchingShareUrl: true,
  fetchingShareUrlError: false,
  shareUrl: null
};

export default () => {
  const [state, setState] = useSetState(initialState);

  const [
    fetchPaymentProtectionInsuranceData,
    { data, refetch }
  ] = usePaymentProtectionInsuranceDataQuery({
    onError: () => {
      setState({
        fetchingError: true,
        fetching: false
      });
    },
    onCompleted: () => {
      setState({
        fetchingError: false,
        fetching: false
      });
    }
  });

  useEffect(() => {
    fetchPaymentProtectionInsuranceData();
  }, []);

  const ppiData = data?.shopper?.paymentProtectionInsurance || {};
  const locale = ppiData.locale || "";
  const environment = ppiData.environment || "";
  const shopperPublicToken = ppiData.shopperPublicToken || "";

  return {
    ...state,
    locale,
    environment,
    shopperPublicToken,
    refetch
  };
};
